<template>
  <el-dialog :title="dialogtitle" v-model="isshow" width="50%" @close="onClose">
    <!-- 商品列表 -->
    <div v-if="linktype == 'goods'" class="link-header">
      <div class="goods-header">
        <el-cascader
          :options="option"
          v-model="categoryid"
          placeholder="选择品类"
          clearable
          style="margin-right: 10px; margin-bottom: 10px"
          :show-all-levels="false"
          :props="props"
          @change="SetBrand"
        ></el-cascader>

        <el-select
          v-model="brandid"
          placeholder="选择品牌"
          filterable
          style="margin-right: 10px; margin-bottom: 10px; width: 150px"
          clearable
          :disabled="categoryid ? false : true"
          @change="SetModel"
        >
          <el-option
            v-for="brand in brands"
            :key="brand.ID"
            :label="brand.Brand"
            :value="brand.ID"
          ></el-option>
        </el-select>

        <el-select
          v-model="modelid"
          placeholder="选择型号"
          filterable
          style="margin-right: 10px; margin-bottom: 10px; width: 150px"
          clearable
          :disabled="brandid ? false : true"
          :loading="loading"
          @visible-change="GetModel"
        >
          <el-option
            v-for="model in models"
            :key="model.ID"
            :label="model.Model"
            :value="model.ID"
          ></el-option>
        </el-select>

        <el-input
          v-model="keyword"
          class="search-input"
          placeholder="商品标题/商品编码"
          @keyup.enter="searchPdt"
        ></el-input>
        <el-button type="success" @click="searchPdt()">搜索</el-button>
      </div>

      <el-table
        :data="goodslist"
        class="link-list"
        width="100%"
        v-loading="goodsloading"
        element-loading-text="拼命加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        size="small"
        ref="goodsTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="30"
          prop="ID"
          v-if="selecttype == 'checkbox'"
          class-name="goods-checkbox"
        >
        </el-table-column>
        <el-table-column
          label="商品主图"
          width="80"
          v-if="selecttype == 'checkbox'"
        >
          <template #default="scope">
            <el-image class="pdt-thumb" :src="scope.row.PdtThumb" />
          </template>
        </el-table-column>

        <el-table-column
          label="商品主图"
          width="80"
          v-if="selecttype == 'radio'"
        >
          <template #default="scope">
            <el-radio v-model="goodsindex" :label="scope.$index">
              <el-image class="pdt-thumb" :src="scope.row.PdtThumb"
            /></el-radio>
          </template>
        </el-table-column>
        <el-table-column
          label="商户名称：商品名称"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ scope.row.Merchant }}：{{ scope.row.PdtName }}
          </template>
        </el-table-column>
        <el-table-column label="商品地址" width="200">
          <template #default="scope">
            /pages/goods/goods?id={{ scope.row.ID }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="getGoodsList"
        v-model:currentPage="curpage"
        :page-size="pagesize"
        layout="total, prev, pager, next, jumper"
        :total="totalcount"
        small="true"
      >
      </el-pagination>
    </div>

    <!-- 页面链接 -->
    <el-table
      v-if="linktype == 'page'"
      :data="pages"
      class="link-list"
      width="100%"
      v-loading="loading"
      element-loading-text="拼命加载中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      size="small"
    >
      <el-table-column label="页面标题">
        <template #default="scope">
          <el-radio v-model="pageindex" :label="scope.$index">{{
            scope.row.title
          }}</el-radio>
        </template>
      </el-table-column>
      <el-table-column
        label="页面地址"
        prop="link"
        width="300"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>

    <div v-if="linktype == 'coupon'" class="link-header">
      <div class="goods-header">
        <el-select
          v-model="coupontype"
          placeholder="请选择优惠券类型"
          filterable
          style="margin-right: 10px"
          clearable
          @change="changeCouponType"
        >
          <el-option label="首期款租金券" value="1"></el-option>
          <el-option label="延长还机时间" value="2"></el-option>
        </el-select>
        <el-input
          v-model="keyword"
          class="search-input"
          placeholder="优惠券名称"
          @keyup.enter="searchCoupon"
        ></el-input>
        <el-button type="success" @click="searchCoupon()">搜索</el-button>
      </div>

      <el-table
        :data="couponlist"
        class="link-list"
        width="100%"
        v-loading="goodsloading"
        element-loading-text="拼命加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        size="small"
      >
        <el-table-column label="优惠券名称"
          show-overflow-tooltip>
          <template #default="scope">
            <el-radio v-model="goodsindex" :label="scope.$index">{{
              scope.row.CouponName
            }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column label="优惠券类型" width="100">
          <template #default="scope">
            <span v-if="scope.row.CouponType == 1">首期款租金券</span>
            <span v-if="scope.row.CouponType == 2">延长还机时间</span>
          </template>
        </el-table-column>
        <el-table-column label="使用条件" width="120">
          <template #default="scope">
            <span v-if="scope.row.CouponType == 1"
              >满{{ scope.row.ConditionVal }}元可用</span
            >
            <span v-if="scope.row.CouponType == 2"
              >用户租满{{ scope.row.ConditionVal }}天可用</span
            >
          </template>
        </el-table-column>
        <el-table-column label="面额/折扣" width="80">
          <template #default="scope">
            <span v-if="scope.row.CouponType == 1"
              >{{ scope.row.CouponValue }}元</span
            >
            <span v-if="scope.row.CouponType == 2"
              >延长{{ scope.row.CouponValue }}天</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="使用有效期结束"
          show-overflow-tooltip
        >
          <template #default="scope">
            <span v-if="scope.row.UseTimeType == 1"
              >自领取起{{ scope.row.UseTime }}天内有效</span
            >
            <span
              v-if="scope.row.UseTimeType == 2 && scope.row.ExpireDTime != 0"
            >
              {{ formatDate(scope.row.ExpireDTime) }}
            </span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="getCouponList"
        v-model:currentPage="curpage"
        :page-size="pagesize"
        layout="total, prev, pager, next, jumper"
        :total="totalcount"
        small="true"
      >
      </el-pagination>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import pageconst from "@/pageconst";
import { ElMessage } from "element-plus";

export default {
  name: "linkblock",
  props: {
    linktype: {
      type: String,
    },
    showlink: {
      type: Boolean,
      default: false,
    },
    curgoodsid: {
      type: String,
    },
    curlink: {
      type: String,
    },
    selgoods: {
      type: String,
      default: "radio",
    },
    goodsids: {
      type: Array,
    },
    goodscount: {
      type: Number,
      default: 10000,
    },
  },
  watch: {
    linktype: function (newValue, oldValue) {
      console.log("改变连接类型: " + oldValue, newValue);
      this.dialogtitle =
        newValue === "page"
          ? "选择目标页面"
          : newValue === "goods"
          ? "选择商品"
          : "";
    },
    showlink: function (newValue, oldValue) {
      console.log("是否显示连接模块: " + newValue, oldValue);
      this.isshow = newValue;
      this.curpage = 1;
      this.keyword = "";
      this.categoryid = "";
      this.modelid = "";
      this.brandid = "";

      if (newValue) {
        if (this.linktype == "page") this.getPageList();
        if (this.linktype == "goods") this.getGoodsList();
        if (this.linktype == "coupon") this.getCouponList();
      }
    },
    selgoods: function (newValue, oldValue) {
      console.log("商品选择类型：", newValue, oldValue);
      this.selecttype = newValue;
    },
    goodsids: function (newValue, oldValue) {
      console.log("已选择商品：", newValue, oldValue);
      // 不勾选当前选中的商品
      //this.curgoodsids = newValue;
      //this.arrc = newValue;

      this.curgoodsids = [];
      this.arrc = [];
    },
    goodscount: function (newValue, oldValue) {
      console.log(newValue, oldValue);
      this.selgoodscount = newValue;
    },
  },
  data: function () {
    return {
      isshow: false,
      dialogtitle: "",
      curpage: 1,
      pagesize: 10,
      totalcount: "",
      pages: [],
      pageindex: "",
      goodslist: [],
      goodsindex: "",
      goodsloading: false,
      selecttype: "radio",
      curgoodsids: [],
      arrc: [], // 已选择的商品
      goodscursel: [],
      curpageids: [],
      selgoodscount: 10000,

      props: {
        label: "label",
        value: "value",
        disabled: "Disabled",
      },
      option: [],
      categoryid: "",
      brands: [],
      brandid: "",
      loading: false,
      models: [],
      modelid: "",
      keyword: "",
      nocatesel: true,
      nobrandsel: true,
      status: 1,
      coupontype: "",
      couponlist: [],
    };
  },
  mounted() {},
  methods: {
    handleSelectionChange(val) {
      if (!this.goodsloading) {
        this.goodscursel = [];
        val.forEach((goods) => {
          this.goodscursel.push(goods.ID);
        });

        let isect = this.intersect(this.goodscursel, this.curpageids);
        //console.log("isect", isect);

        let setf = this.difference(this.goodscursel, isect);
        //console.log("setf", setf);

        let setg = this.difference(this.curpageids, isect);
        //console.log("setg", setg);

        //console.log("seth", this.difference(this.arrc, setg));

        this.arrc = this.unionArray(setf, this.difference(this.arrc, setg));
        //console.log("arrc", this.arrc);
      }
    },
    intersect(a, b) {
      let set1 = new Set(a),
        set2 = new Set(b);
      return [...new Set([...set1].filter((x) => set2.has(x)))];
    },
    difference(a, b) {
      let set1 = new Set(a),
        set2 = new Set(b);
      return [
        ...new Set([...set1].filter((x) => !set2.has(x))),
        ...new Set([...set2].filter((x) => !set1.has(x))),
      ];
    },
    unionArray() {
      let tempArray = [];
      for (let i = 0; i < arguments.length; i++) {
        tempArray.push(...new Set(arguments[i]));
      }
      return [...new Set(tempArray)];
    },
    onClose() {
      this.curgoodsids = [];
      this.arrc = [];
      this.isshow = false;
      this.$emit("close", false);
    },
    searchPdt() {
      this.curpage = 1;
      this.getGoodsList();
    },
    getPageList() {
      console.log("getPageList curlink: " + this.curlink);
      this.pageindex = "";
      this.axios
        .get(pageconst.pagemanage + "?entry=getpages", {
          headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => {
          var { pages } = data;
          this.pages = pages;
          for (var i = 0; i < pages.length; i++) {
            if (pages[i].link == this.curlink) {
              this.pageindex = i;
            }
          }
        });
    },
    getGoodsList() {
      //console.log(this.goodscursel);
      var that = this;
      this.goodsindex = "";
      this.goodsloading = true;

      this.axios
        .get(pageconst.get_pdt_url, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            keyword: that.keyword,
            categoryid: JSON.stringify(that.categoryid),
            brandid: that.brandid,
            modelid: that.modelid,
            status: 2,
            curpage: that.curpage,
            pagesize: that.pagesize,
          },
        })
        .then(({ data }) => {
          console.log(data);
          if (that.selecttype == "radio") {
            for (let i = 0; i < data.list.length; i++) {
              if (data.list[i].ID == that.curgoodsid) {
                that.goodsindex = i;
              }
            }
          }

          that.goodslist = data.list;
          that.totalcount = data.totalcount * 1;
          that.curpage = data.curpage * 1;

          that.goodsloading = false;
        });
    },
    onSubmit() {
      if (this.selecttype == "radio") {
        let link = "",
          linkname = "",
          goodsid = "";
        if (this.linktype == "page") {
          link = this.pages[this.pageindex].link;
          linkname = this.pages[this.pageindex].title;
        } else if (this.linktype == "goods") {
          let goods = this.goodslist[this.goodsindex];
          link = "/pages/goods/goods?pdtid=" + goods.ID;
          linkname = goods.PdtName;
          goodsid = goods.ID;
        } else if (this.linktype == "coupon") {
          let coupon = this.couponlist[this.goodsindex];
          link = "/pages/coupon/coupon?couponid=" + coupon.ID;
          linkname = coupon.CouponName;
          goodsid = coupon.ID;
        }
        this.$emit("submit", link, linkname, goodsid);
      } else if (this.selecttype == "checkbox") {
        if (this.arrc.length > this.selgoodscount) {
          ElMessage.warning({
            message: "最多只能选择 " + this.selgoodscount + " 个商品",
            type: "warning",
            center: true,
          });
          return false;
        }

        this.$emit("submit", "", "", this.arrc);
      }

      this.onClose();
    },

    SetBrand() {
      this.brandid = "";
      this.modelid = "";
    },
    SetModel() {
      this.modelid = "";
    },
    GetModel() {
      this.loading = true;
      this.axios
        .get(pageconst.get_plan_model_url, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            category: JSON.stringify(this.categoryid),
            brand: this.brandid,
            status: 1,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.models = response.data;
          this.loading = false;
        });
    },

    changeCouponType(e) {
      this.coupontype = e;
    },

    searchCoupon() {
      this.curpage = 1;
      this.getCouponList();
    },
    getCouponList() {
      var that = this;
      this.goodsindex = "";
      this.goodsloading = true;

      this.axios
        .get(pageconst.get_coupon_url, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            keyword: that.keyword,
            coupontype: that.coupontype,
            curpage: that.curpage,
            pagesize: that.pagesize,
          },
        })
        .then(({ data }) => {
          console.log(data);
          for (let i = 0; i < data.list.length; i++) {
            if (data.list[i].ID == that.curgoodsid) {
              that.goodsindex = i;
            }
          }

          that.couponlist = data.list;
          that.totalcount = data.totalcount * 1;
          that.curpage = data.curpage * 1;

          that.goodsloading = false;
        });
    },
  },
  created() {
    // 读取品类列表
    this.axios
      .get(pageconst.get_cascader_url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.option = response.data;
      });

    // 读取品牌列表
    this.axios
      .get(pageconst.get_brand_url, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          status: 1,
        },
      })
      .then((response) => {
        this.brands = response.data;
      });
  },
};
</script>

<style scoped>
:deep() .el-table th {
  background-color: #f0f0f0;
}

.goods-header {
  margin-bottom: 20px;
}

:deep() .el-pagination {
  margin-top: 20px;
}

.link-header {
  padding-right: 6px;
  max-height: 540px;
  overflow-y: scroll;
}
.link-header::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.link-header::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}

.link-list {
  border: 1px solid #ebeef5;
  border-bottom: 0;
}

.link-list .pdt-thumb {
  width: 40px;
  height: 40px;
  padding: 2px;
  border: 1px solid #ddd;
}

:deep() .el-table .cell {
  max-height: 40px;
}

:deep() .el-radio .el-radio__label {
  padding-left: 5px;
  vertical-align: middle;
}

:deep() .goods-checkbox .cell {
  text-overflow: unset;
}
</style>